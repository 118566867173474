@use "../globals" as *;
@use "sass:map";

// Container Spacers
.container:not(.no-spacers),
.container-fluid:not(.no-spacers),
.container.con-spacer,
.con-spacer {
  padding-top: map.get($con-spacer, "normal");
  padding-bottom: map.get($con-spacer, "normal");
  @include media-below($mobile) {
    padding-top: map.get($con-spacer, "normal-mobile");
    padding-bottom: map.get($con-spacer, "normal-mobile");
  }
}
.container.con-spacer-big,
.con-spacer-big {
  padding-top: map.get($con-spacer, "big");
  padding-bottom: map.get($con-spacer, "big");
  @include media-below($mobile) {
    padding-top: map.get($con-spacer, "big-mobile");
    padding-bottom: map.get($con-spacer, "big-mobile");
  }
}

// Add space under columns and remove same spacer from row.
// Like this columns underneath have the correct distance.
.row:not(.no-spacers) {
  margin-bottom: -$spacer;
  > [class*="col-"] {
    margin-bottom: $spacer;
  }
}

// Margin Spacer for Container.fusion
.con-m-y {
  margin-top: map.get($con-margin-spacer, "normal");
  margin-bottom: map.get($con-margin-spacer, "normal");
  @include media-below($mobile) {
    margin-top: map.get($con-margin-spacer, "normal-mobile");
    margin-bottom: map.get($con-margin-spacer, "normal-mobile");
  }
  &.big-spacer {
    margin-top: map.get($con-margin-spacer, "big");
    margin-bottom: map.get($con-margin-spacer, "big");
    @include media-below($mobile) {
      margin-top: map.get($con-margin-spacer, "big-mobile");
      margin-bottom: map.get($con-margin-spacer, "big-mobile");
    }
  }
}
.con-m-t {
  margin-top: map.get($con-margin-spacer, "normal");
  @include media-below($mobile) {
    margin-top: map.get($con-margin-spacer, "normal-mobile");
  }
  &.big-spacer {
    margin-top: map.get($con-margin-spacer, "big");
    @include media-below($mobile) {
      margin-top: map.get($con-margin-spacer, "big-mobile");
    }
  }
}
.con-m-b {
  margin-bottom: map.get($con-margin-spacer, "normal");
  @include media-below($mobile) {
    margin-bottom: map.get($con-margin-spacer, "normal-mobile");
  }
  &.big-spacer {
    margin-bottom: map.get($con-margin-spacer, "big");
    @include media-below($mobile) {
      margin-bottom: map.get($con-margin-spacer, "big-mobile");
    }
  }
}
.col-m-b-0 {
  margin-bottom: 0 !important;
}

// Element Spacers (e.g. first div in col-*-*)
.el-spacer {
  padding: map.get($el-spacer, "normal");
}
.el-spacer-big {
  padding: map.get($el-spacer, "big");
}

// Spacer NodeType
.spacer-in-backend {
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: "SPACER";
    color: #999999;
    display: block;
    font-size: 14px;
  }
}

.spacer-half {
  height: $spacer-half;
}
.spacer-normal {
  height: $spacer;
}
.spacer-double {
  height: $spacer*2;
}
.spacer-triple {
  height: $spacer*3;
}
.spacer-quadruple {
  height: $spacer*4;
}
