@use "../globals" as *;

//// Include Icons
//@font-face {
//  font-family: 'icomoon';
//  src:  url('@assets/Fonts/icomoon/fonts/icomoon.eot');
//  src:  url('@assets/Fonts/icomoon/fonts/icomoon.eot#iefix') format('embedded-opentype'),
//  url('@assets/Fonts/icomoon/fonts/icomoon.ttf') format('truetype'),
//  url('@assets/Fonts/icomoon/fonts/icomoon.woff') format('woff'),
//  url('@assets/Fonts/icomoon/fonts/icomoon.svg#icomoon') format('svg');
//  font-weight: normal;
//  font-style: normal;
//  font-display: block;
//}
//
//// Include Fonts
//// Remember! If you use NEOS, change the path to the font files in Settings.yaml (Moon.Preload) as well
//@font-face {
//  font-family: $font-face-family-regular;
//  src: url('@assets/Fonts/Roboto/split/Roboto-Variable.ttf')format("truetype-variations");
//  font-weight: normal;
//  font-style: normal;
//  font-display: swap;
//}
//@font-face {
//  font-family: $font-face-family-bold;
//  src: url('@assets/Fonts/Roboto/static/Roboto-Medium.ttf')format("truetype");
//  font-weight: normal;
//  font-style: normal;
//  font-display: swap;
//}

%positive-coloration {
  color: $black;
}
%negative-coloration {
  color: $white;
}

// Headlines
h1, .h1 {
  font-family: $font-family-bold;
  font-size: $font-h1;
  line-height: 1.2;
  margin-bottom: $spacer-half;
  font-weight: normal;
  @include media-below($mobile) {
    font-size: $font-h1-mobile;
  }
}
h2, .h2 {
  font-family: $font-family-bold;
  font-size: $font-h2;
  line-height: 1.2;
  margin-bottom: $spacer-half;
  font-weight: normal;
  @include media-below($mobile) {
    font-size: $font-h2-mobile;
  }
}
h3, .h3 {
  font-family: $font-family-bold;
  font-size: $font-h3;
  line-height: 1.5;
  margin-bottom: $spacer-half;
  font-weight: normal;
  @include media-below($mobile) {
    font-size: $font-h3-mobile;
  }
}
h4, .h4 {
  font-family: $font-family-bold;
  font-size: $font-h4;
  line-height: 1.5;
  margin-bottom: $spacer-half;
  font-weight: normal;
  @include media-below($mobile) {
    font-size: $font-h4-mobile;
  }
}

// Copytext
p, .p {
  font-size: $font-text;
  line-height: 1.5;
  margin-bottom: $spacer-half;
  @include media-below($font-text-breakpoint) {
    font-size: $font-text-breakpoint-small;
    line-height: 1.3;
  }
}

// Spacer headline after paragraph
p + h1, p + .h1,
p + h2, p + .h2,
p + h3, p + .h3,
p + h4, p + .h4 {
  margin-top: $spacer*2;
}