@use "../globals" as *;

#content {
  .parallax-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    z-index: -1;
    @include media-below($xl) {
      transform: scaleX(-1);
    }
  }
}

%positive-coloration {

}

%negative-coloration {

}
