@use "../globals" as *;

#content {

  a:not(.btn) {
    text-decoration: none;
    &:hover,
    &:active {
      // colors only down below
    }
  }

  .btn,
  .btn-outline {
    display: inline-block;
    padding: $spacer-half $spacer;
    text-decoration: none;
    margin-top: $spacer-half;
    transition: all 250ms ease-in-out;
    margin-right: $spacer;
    &:hover,
    &:active {
      // colors only down below
    }
  }

  .btn + .btn {
    margin-right: 0;
  }

}

%positive-coloration {
  a:not(.btn) {
    color: $primary;
    &:hover,
    &:active { }
  }

  .btn {
    background-color: $primary;
    border: 2px solid $primary;
    color: $white;
    &:hover,
    &:focus {
      background-color: darken($primary, 10);
      border: 2px solid darken($primary, 10);
      color: $white;
      transform: scale(1.025);
    }
  }
  .btn-outline {
    background-color: transparent;
    border: 2px solid $primary;
    color: $primary;
    &:hover,
    &:focus {
      background-color: darken($primary, 10);
      border: 2px solid darken($primary, 10);
      color: $white;
      transform: scale(1.025);
    }
  }
}

%negative-coloration {
  a:not(.btn) {
    color: $primary;
    &:hover,
    &:active { }
  }

  .btn {
    background-color: $secondary;
    color: $black;
  }
}
