@use "../globals" as *;

// column-count for text grid
// The parent element needs column-config() or the class .col-count-cfg
// also the parent element needs column-count() or the class .col-count-(1,2,3,4)
// For details on the classes check _textgrid.scss
@mixin column-config($gap: $spacer-half) {
  -webkit-column-gap: $gap;
  -moz-column-gap: $gap;
  column-gap: $gap;
}

@mixin column-count($amount: 2) {
  -webkit-column-count: $amount;
  -moz-column-count: $amount;
  column-count: $amount;
}

// The parent element need the class .col-count-cfg
// and the class .col-count-(1,2,3,4)
.col-count-cfg {
  @include column-config();
}

.col-count {

  &-1 {
    @include column-count(1);
  }

  &-2 {
    @include column-count(2);
    @include media-below($mobile) {
      @include column-count(1);
    }
  }

  &-3 {
    @include column-count(3);
    @include media-below($tablet) {
      @include column-count(2);
    }
    @include media-below($mobile) {
      @include column-count(1);
    }
  }

  &-4 {
    @include column-count(4);
    @include media-below($tablet) {
      @include column-count(2);
    }
    @include media-below($mobile) {
      @include column-count(1);
    }
  }

}
