@use "../globals" as *;

// Display
.d-flex {
  display: -ms-flexbox!important;
  display: flex!important;
}

.d-block {
  display: block!important;
}

.d-inline-block {
  display: inline-block!important;
}

.d-none,
.hide {
  display: none !important;
}

// Hide on device width
*:not(.hideOn-in-backend) {
  &.hideOn-sm {@include media-between($xs, $sm) {display: none !important;}}
  &.hideOn-md {@include media-between($sm, $md) {display: none !important;}}
  &.hideOn-lg {@include media-between($md, $lg) {display: none !important;}}
  &.hideOn-xl {@include media-between($lg, $xl) {display: none !important;}}
  &.hideOn-xxl {@include media-above($xl) {display: none !important;}}
}
@mixin hideOn-in-backend() {
  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: rgba($black, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $white;
    padding: $spacer;
    content: 'This content is not visible on this device width.\A Can be changed in the "Visibility" settings.';
    font-size: $font-text-small;
    white-space: pre-wrap;
  }
  position: relative;
  height: 100px;
  overflow: hidden;
}
.hideOn-in-backend {
  &.hideOn-sm {@include media-between($xs, $sm) {@include hideOn-in-backend();}}
  &.hideOn-md {@include media-between($sm, $md) {@include hideOn-in-backend();}}
  &.hideOn-lg {@include media-between($md, $lg) {@include hideOn-in-backend();}}
  &.hideOn-xl {@include media-between($lg, $xl) {@include hideOn-in-backend();}}
  &.hideOn-xxl {@include media-above($xl) {@include hideOn-in-backend();}}
}

// Reverse on mobile classes
.reverseOn-sm-v {@include media-between($xs, $sm) {flex-direction: column-reverse;}}
.reverseOn-md-v {@include media-between($sm, $md) {flex-direction: column-reverse;}}
.reverseOn-lg-v {@include media-between($md, $lg) {flex-direction: column-reverse;}}
.reverseOn-xl-v {@include media-between($lg, $xl) {flex-direction: column-reverse;}}
.reverseOn-xxl-v {@include media-above($xl) {flex-direction: column-reverse;}}
.reverseOn-sm-h {@include media-between($xs, $sm) {flex-direction: row-reverse;}}
.reverseOn-md-h {@include media-between($sm, $md) {flex-direction: row-reverse;}}
.reverseOn-lg-h {@include media-between($md, $lg) {flex-direction: row-reverse;}}
.reverseOn-xl-h {@include media-between($lg, $xl) {flex-direction: row-reverse;}}
.reverseOn-xxl-h {@include media-above($xl) {flex-direction: row-reverse;}}
