.lottie-parent {
  position: relative;
}

.lottie-static {
  > * {display: flex;}
}

.lottie-sticky {
  position: sticky;
  top: 0;
  > * {display: flex;}
}
