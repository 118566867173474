@use "variables" as *;
@use "base-extend";
@use "components";

/*

    Use this file to set the basic color settings

*/

//
// Element & Background coloration
//
// Just include %positive-coloration() {} or %negative-coloration {} in your component to add rules.
// Use the placeholder for the background if you want to make special rules.
//
// You don't need to change anything for it here! Only if you add a new background.
//
%bg-white-coloration {
  background-color: $white;
  @extend %positive-coloration !optional;
}

%bg-grey-coloration {
  background: rgb(0,0,0);
  background: -moz-linear-gradient(153deg, rgba(0,0,0,1) 0%, rgba(20,24,29,1) 100%);
  background: -webkit-linear-gradient(153deg, rgba(0,0,0,1) 0%, rgba(20,24,29,1) 100%);
  background: linear-gradient(153deg, rgba(0,0,0,1) 0%, rgba(20,24,29,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#14181d",GradientType=1);
  @extend %negative-coloration !optional;
}

%bg-black-coloration {
  background-color: $black;
  @extend %negative-coloration !optional;
}

%bg-transparent-black-coloration {
  @extend %positive-coloration !optional;
}

%bg-transparent-white-coloration {
  @extend %negative-coloration !optional;
}

/*
NORMALLY YOU DON'T HAVE TO EDIT DOWN BELOW
JUST ADD NEW CLASSES WHEN YOU CREATED A NEW BACKGROUND
*/

// STANDARD BG COLOR RULES
#content .bg-white {@extend %bg-white-coloration;}
#content .bg-grey {@extend %bg-grey-coloration;}
#content .bg-black {@extend %bg-black-coloration;}
#content .bg-transparent-black {@extend %bg-transparent-black-coloration;}
#content .bg-transparent-white {@extend %bg-transparent-white-coloration;}
// THIS SECTION HAVE TO BE BELOW STANDARD BG COLOR RULES
// To prevent wrong inheritance we need a bit more specificity for
// our sub-bg- selectors, therefore we use #{$content-selector}#content.
#{$content-selector}#content .sub-bg-white {@extend %bg-white-coloration;}
#{$content-selector}#content .sub-bg-grey {@extend %bg-grey-coloration;}
#{$content-selector}#content .sub-bg-black {@extend %bg-black-coloration;}
#{$content-selector}#content .sub-bg-transparent-black {@extend %bg-transparent-black-coloration;}
#{$content-selector}#content .sub-bg-transparent-white {@extend %bg-transparent-white-coloration;}
