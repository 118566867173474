@use "sass:math";
/*

    All variables are created in this file. A small preselection is already available

*/

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
//
// Colors
//
$primary: #DA291C;
$secondary: #0C96FF;
$tertiary: #9b06e9;

$white: #ffffff;
$black: #1A1A1A;

// The selector where the #content id is placed in the html
$content-selector: div;

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
//
// Font families
//
$font-face-family-regular: "Arial";
$font-family-regular: $font-face-family-regular, sans-serif;
$font-family-bold: $font-family-regular;

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
//
// Font sizes
//
$font-h1: 4.4rem; //44px
$font-h2: 3.2rem; //32px
$font-h3: 2.4rem; //24px
$font-h4: 2.1rem; //21px

$font-h1-mobile: 3.4rem; //34px
$font-h2-mobile: 2.8rem; //28px
$font-h3-mobile: 2.0rem; //20px
$font-h4-mobile: 1.8rem; //18px

$font-text: 2.4rem; //18px
$font-text-small: 1.8rem; //14px
$font-text-big: 4.0rem; // 20px

$font-text-breakpoint: 900px;
$font-text-breakpoint-small: 2.0rem; //18px
$font-text-breakpoint-big-small: 3.0rem;

$font-scroll-text-animation: 9.2rem;
$font-scroll-text-animation-small: 7.2rem;

$font-quote: 3.6rem; // 36px
$font-quote-tablet: 3.0rem; // 30px
$font-quote-mobile: 2.6rem; // 26px

$font-sub-sup: 50%;

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
//
// Menu sizes
//
$sidebar-width-desktop: 320px;
$main-menu-height-desktop: 112px;
$main-menu-height-desktop-scroll: 80px;
$main-menu-height-mobile: 80px;
$main-menu-height-mobile-scroll: 80px;

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
//
// Spacers
//
$spacer: 30px;
$spacer-half: math.div($spacer, 2);
$spacer-half-half: math.div($spacer, 2.5);
$spacer-third: math.div($spacer, 3);
$spacer-quarter: math.div($spacer, 4);
$spacer-fiveth: math.div($spacer, 5);

// Padding top and bottom for .container
$con-spacer: (
  "normal": $spacer*6,
  "normal-mobile": $spacer*3,
  "big": $spacer*10,
  "big-mobile": $spacer*5
);

// Margin top and/or bottom for .container
$con-margin-spacer: (
  "normal": $spacer,
  "normal-mobile": $spacer-half,
  "big": $spacer*2,
  "big-mobile": $spacer
);

// padding for first div in .col-*-*
$el-spacer: (
  "normal": $spacer-half,
  "big": $spacer
);

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
//
// Grid system (modify if needed)
//

// Breakingpoints for Gridsystem
$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

// Grid columns
$grid-gutter-width: $spacer;

// Breaking points for devices
$desktop: $xl;
$tablet: $lg;
$mobile: $md;

// Page width for Breaking points
$page-width-xs: $xs;
$page-width-sm: $md;
$page-width-md: $lg - $spacer*2;
$page-width-lg: $xl - $spacer*2;
$page-width-xl: 1440px - $spacer*2;

//
// From here it get a bit tricky
$gutter-width: math.div($grid-gutter-width, 2);
$outer-margin: $grid-gutter-width;
$outer-margin-half: math.div($outer-margin, 2);
$gutter-compensation: calc(#{$gutter-width} * -1);
$half-gutter-width: #{$gutter-width};
$xs-min: $xs;
$xs-width: $page-width-xs;
$sm-min: $sm;
$sm-width: $page-width-sm;
$md-min: $md;
$md-width: $page-width-md;
$lg-min: $lg;
$lg-width: $page-width-lg;
$xl-min: $xl;
$xl-width: $page-width-xl;

$xs-viewport: "only screen and (min-width: #{$xs-min})";
$sm-viewport: "only screen and (min-width: #{$sm-min})";
$md-viewport: "only screen and (min-width: #{$md-min})";
$lg-viewport: "only screen and (min-width: #{$lg-min})";
$xl-viewport: "only screen and (min-width: #{$xl-min})";

$xs-only: "only screen and (max-width: #{$sm-min - 1})";
$sm-only: "only screen and (min-width: #{$sm-min}) and (max-width: #{$md-min - 1})";
$md-only: "only screen and (min-width: #{$md-min}) and (max-width: #{$lg-min - 1})";
$lg-only: "only screen and (min-width: #{$lg-min}) and (max-width: #{$xl-min - 1})";
$xl-only: "only screen and (min-width: #{$xl-min})";
