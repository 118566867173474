@use "../globals" as *;

// General setup
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%; // for safari landscape orientation bug
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: $font-family-regular;
  font-size: $font-text;
  margin: 0;
  padding: 0;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Section as flex (needed for wordpress cms)
section {
  display: flex;
}

// Hide Scrollbar
.hideScrollbarX {
  overflow-x: hidden;
}
.hideScrollbarY {
  overflow-y: hidden;
}

// Shadow
.box-shadow {
  @include box-shadow();
}

// Same height for all sub-bg-
[class*="sub-bg-"] {
  height: 100%;
  align-items: flex-start; /*needed for correct width of flex items inside*/
}
