img,
.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-height-fluid {
  width: auto !important;
  max-height: 100% !important;
}
