@use "globals" as *;

/*
If you have to change something in the base layout.
Do it here!
*/

body {
  font-variation-settings: "wght" 400;
  background-color: $black;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.bg-image {
  background-color: rgba(20,24,29,1);
  background-repeat: no-repeat;
  background-position: right bottom;
  @include media-below($xl) {background-size: 800px;}
  @include media-below($lg) {background-size: 600px;}
  @include media-below($md) {background-size: 500px;}
  @include media-below(750px) {
    //background-position: 35vw bottom;
    //background-size: 100%;
  }
}

.container {
  overflow: hidden;
}

.t-shadow {
  text-shadow: 0 0 10px black;
}
