// Position
.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.flex-start {
  align-self: flex-start !important;
}

.flex-end {
  align-self: flex-end !important;
}

.pos-r {
  position: relative;
}

.pos-a {
  position: absolute;
}

// Vertical Alignment NodeType
.vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.vertical-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

// Horizontal Alignment NodeType
.horizontal-center {
  margin-left: auto;
  margin-right: auto;
}
.horizontal-right {
  margin-left: auto;
}

// Neos aligments
.neos-alignment-center {
  text-align: center;
}
.neos-alignment-left {
  text-align: left;
}
.neos-alignment-right {
  text-align: right;
}
