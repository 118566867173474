@use "../globals" as *;

#content {

  ul {
    list-style-type: "•  ";
    padding-left: $spacer-half-half;
    margin-bottom: $spacer-half;

    li {
      margin-bottom: $spacer-quarter;
      &:last-child {
        margin-bottom: 0;
      }
    }

    ul, ol {
      margin-top: $spacer-quarter;
      margin-bottom: 0;
    }
  }

  ol {
    list-style: decimal-leading-zero;
    padding-left: $spacer;
    margin-bottom: $spacer-half;

    li {
      margin-bottom: $spacer-quarter;
      &:last-child {
        margin-bottom: 0;
      }
    }

    ul, ol {
      margin-top: $spacer-quarter;
      margin-bottom: 0;
    }
  }

}
