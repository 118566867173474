@use "../globals" as *;

/* Portrait */
@media screen and (max-width: $md) and (orientation:portrait) {
  body {overflow: hidden;}
  #content .rotate-message {display: flex !important;}
}
///* Landscape */
//@media screen and (min-width: $md + 1) and (orientation:landscape) {
//  #content .rotate-message {display: none;}
//}

#content {

  .rotate-message {
    background-color: $black;
    color: $white;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;

    padding: $spacer;
    text-align: center;

    display: none;
    justify-content: center;
    align-items: center;
  }

}

%positive-coloration {

}

%negative-coloration {

}
